.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 90px;
}

.mean-nav {
  display: none; /* Initially hide the navigation menu */
}

.mean-nav-open {
  display: block; /* Show the navigation menu when mean-nav-open class is present */
}
.dropdown-menu {
  display: none; /* Initially hide the navigation menu */
}

.dropdown-menu-open {
  display: block; /* Show the navigation menu when mean-nav-open class is present */
}
.owl-theme .owl-dots .owl-dot {
  zoom: 1;
  display: inline-block;
  margin: 0 3px;
}

.owl-theme .owl-dots .owl-dot span {
  border: 0;
  background: #d0cccc;
  width: 10px;
  font-size: 0;
  height: 10px;
  border-radius: 10px;
  z-index: 3;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  width: 30px;
  background-color: #037b9d;
}

.section-title .border-left-1 {
  height: 3px;
  width: 40px;
  background: #037b9d;
  left: -15px;
  display: inline-block;
  position: relative;
  top: -7px;
}
.section-title .border-right-1 {
  height: 3px;
  width: 40px;
  background: #037b9d;
  right: -15px;
  display: inline-block;
  top: -7px;
  position: relative;
}
.section-title > span {
  color: #037b9d;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
}
.par-6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  color: #828697;
}
.hero-1-bg {
  padding: 150px 0 100px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .box-max-height {
    min-height: 325px;
  }
  .box-max-height-1 {
    min-height: 580px;
  }
}
.acavo-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  width: 330px;
}
.link-hover {
  font-weight: 500;
}

.link-hover:hover {
  color: #4f4c72;
}
.error {
  color: #f84257;
}
